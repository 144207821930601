// Note: Dictionary Log Utility

// Checks if an object or its nested objects contain any keys that include the substring "error".
export function containsErrorKey(obj) {
    for (const key in obj) {
        if (key.includes("error")) {
            return true;
        }
        
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            if (containsErrorKey(obj[key])) {
                return true;
            }
        }
    }
    return false;
}