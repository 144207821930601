import React from 'react';
import './Loading.css';
import { observer } from 'mobx-react';
import user from '../../../Mobx/User';
import dictionary from '../../../Mobx/Dictionary';
import groups from '../../../Mobx/Groups';
import dictionaryLog from '../../../Mobx/DictionaryLog';
import Loader from '../Loader/Loader';

const Loading = observer(() => {
    return (
        <div className="loading-container">
            {/* <div className="spinner"></div> */}
            <Loader />
            <div className="text-container">
                <div className="welcome-text">Welcome {user.name}!</div>

                <table className="loading-table">
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Progress</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Categories</td>
                            <td>{groups.isLoading.loaded} / {groups.isLoading.total} MB</td>
                        </tr>
                        <tr>
                            <td>Dictionary Log</td>
                            <td>{dictionaryLog.isLoading.loaded} / {dictionaryLog.isLoading.total} MB</td>
                        </tr>
                        <tr>
                            <td>English to Hebrew Dictionary</td>
                            <td>{dictionary.isLoading.dictionary_eng_to_heb.loaded} / {dictionary.isLoading.dictionary_eng_to_heb.total} MB</td>
                        </tr>
                        <tr>
                            <td>Hebrew to English Dictionary</td>
                            <td>{dictionary.isLoading.dictionary_heb_to_eng.loaded} / {dictionary.isLoading.dictionary_heb_to_eng.total} MB</td>
                        </tr>
                        <tr>
                            <td>User Interface</td>
                            <td>
                                {dictionary.isLoading.all === true ? '' :
                                    dictionary.isLoading.all > 1 ? `${dictionary.isLoading.all}` : 'Ready!'}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
});

export default Loading;
