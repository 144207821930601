import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import dictionary from '../../../Mobx/Dictionary';
import DictionaryStatus from '../DictionaryLog/DictionaryStatus';
import groups from '../../../Mobx/Groups';
import { MultiSelect } from 'react-multi-select-component';
import Swal from 'sweetalert2';
import './DictionaryCard.css';
import { convertMessageToHTML } from '../../../Utils/convertMessageToHTML';
import DictionaryLogList from '../DictionaryLog/DictionaryLogList';
import Popup from '../../SharedArea/Popup/Popup';
import dictionaryLog from '../../../Mobx/DictionaryLog';
import { containsErrorKey } from '../../../Utils/error';

const CreateDictionaryEntry = observer(() => {
    const { register, handleSubmit, reset, setValue } = useForm();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const dictionaryType = dictionary.current.dictionaryType;
    const fields = dictionary.current.fields;

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        try {
            const message = await dictionary.create({ ...data, categories: selectedCategories });
            reset();
            setSelectedCategories([]);
            // Swal.fire({
            //     title: 'Success!',
            //     // text: message,
            //     html: convertMessageToHTML(message),
            //     icon: 'success',
            //     confirmButtonText: 'OK',
            //     width: 'auto'
            // });
        } catch (error) {
            // const log = error.response.data;
            // dictionaryLog.addLog(log);
            // if (containsErrorKey(log))
            //     dictionary.setIsLoading('error');
            // Swal.fire({
            //     title: 'Error!',
            //     // text: `Failed to create entry.\nError: ${error}.\nError description: ${JSON.stringify(error.response.data).replace(/&quot;/g, '"')}`,
            //     html: convertMessageToHTML({ Error: error.message, description: error.response.data }),
            //     icon: 'error',
            //     confirmButtonText: 'OK',
            //     width: 'auto'
            // });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCategoryChange = (selected) => {
        setSelectedCategories(selected);
        setValue('categories', selected);
    };

    // _____________________________________________________________________
    const [isPopupOpen_logs, setIsPopupOpen_logs] = useState(false);
    const openPopup_logs = () => setIsPopupOpen_logs(true);
    const closePopup_logs = () => setIsPopupOpen_logs(false);

    // _____________________________________________________________________

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="create-dictionary-entry">
            {fields.map((field) => {
                if (['_id', 'createdAt', 'updatedAt'].includes(field)) {
                    return null;
                } else if (['pos', 'latin', 'olami', 'band', 'priority'].includes(field)) {
                    const options = groups[field][dictionaryType];
                    return (
                        <div className='selectDiv' key={field}>
                            <label>{field}</label>
                            <select {...register(field)} dir="auto">
                                <option value="">--</option>
                                {options.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    );
                }
                else if (field === 'categories') {
                    const options = groups[field][dictionaryType].map(option => ({
                        label: option.name,
                        value: option._id
                    }));
                    return (
                        <div key={field} className="multi-select-container">
                            <label>{field}</label>
                            <MultiSelect
                                options={options}
                                value={selectedCategories}
                                onChange={handleCategoryChange}
                                labelledBy="Select categories"
                            />
                        </div>
                    );
                }
                else {
                    return (
                        <div className='textDiv' key={field}>
                            <label>{field}</label>
                            <input
                                type="text"
                                dir="auto"
                                {...register(field, { required: field === 'original' })}
                                autoComplete="off"
                            />
                        </div>
                    );
                }
            })}
            <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Create'}
            </button>

            <div className='status'>
                <span className="dictionary-status-span" onClick={openPopup_logs}>
                    <DictionaryStatus />
                </span>
                <Popup
                    isOpen={isPopupOpen_logs}
                    onClose={closePopup_logs}
                    title={'Dictionary Logs'}
                >
                    <DictionaryLogList />
                </Popup>
            </div>

        </form>
    );
});

export default CreateDictionaryEntry;
