import React from 'react';
import { observer } from 'mobx-react';
import dictionary from '../../../Mobx/Dictionary';
import './NumberOfResults.css';

const NumberOfResults = observer(() => {
    const formattedNumber = dictionary.current.sum.toLocaleString();
    return <div className="number-of-results">
        {/* {dictionary.isFiltering ? '...' : formattedNumber} */}
        {formattedNumber}
    </div>;
});

export default NumberOfResults;
