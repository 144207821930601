import React from 'react';
import "./Layout.css";
import { BrowserRouter as Router } from 'react-router-dom';
import { observer } from 'mobx-react';
import user from '../../../Mobx/User';
import Login from '../../User/Login/Login';
import LoginWhiteOAuth from '../../User/Login/LoginWhiteOAuth';
import Logout from '../../User/Logout/Logout';
import Navbar from '../Navbar/Navbar';
import Routing from '../Routing/Routing';
import UserNameDisplay from '../../User/UserNameDisplay/UserNameDisplay';
import dictionary from '../../../Mobx/Dictionary';
import Loading from '../../SharedArea/Loading/Loading';

const Layout = observer(() => {
    return (
        <Router>
            <div className="Layout">
                {!user.name ? (
                    <>
                        <Login />
                        <LoginWhiteOAuth />
                    </>
                ) : (
                    dictionary.isLoading.all ? <Loading /> :
                        <>
                            <header>
                                <Navbar />
                                <div>
                                    <UserNameDisplay />
                                    <Logout />
                                </div>
                            </header>
                            <main>
                                <Routing />
                            </main>
                        </>
                )}
            </div>
        </Router>
    );
});

export default Layout;
