import axios from 'axios';
import user from '../Mobx/User';

function createInterceptors() {
    axios.interceptors.response.use(
        response => {
            return response; // Repeats the response if there is no error
        },
        function (error) {
            if (user.name && error.response && [401, 403, 462].includes(error.response.status)) {
                console.log(`Error code: ${error.response.status}, Message: ${error.response.data}`);
                alert(error.response.data);
                user.logout();
            }
            return Promise.reject(error); // Transfer the error on
        }
    );
}

export default createInterceptors;