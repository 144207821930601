import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import customers from '../../Mobx/Customers';
import Loading from '../SharedArea/Loading/Loading';
import './Customers.css';

import CreateSubscription from '../Payment/CreateSubscription/CreateSubscription';
import CancelSubscription from '../Payment/CancelSubscription/CancelSubscription';

const Customers = observer(() => {
    const [showPasswords, setShowPasswords] = useState({});

    useEffect(() => {
        customers.getCustomers();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('he-IL', {
            year: 'numeric',
            month: '2-digit',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    const togglePasswordVisibility = (index) => {
        setShowPasswords(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    if (customers.customers.length === 0) {
        return <Loading />;
    }

    return (
        <div className='Customers' style={{ width: '100%', height: '585px' }}>

            <div>
                <CreateSubscription />
                <CancelSubscription />
            </div>

            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        width={width}
                        height={height}
                        headerHeight={40}
                        rowHeight={40}
                        rowCount={customers.customers.length}
                        rowGetter={({ index }) => customers.customers[index]}
                        rowClassName={({ index }) => index % 2 === 0 ? 'evenRow' : 'oddRow'}
                    >
                        {customers.customer_fields.map(field => (
                            <Column
                                key={field}
                                label={field}
                                dataKey={field}
                                width={width / customers.customer_fields.length}
                                cellRenderer={({ cellData, rowIndex }) => {
                                    if (field === 'createdAt') {
                                        return formatDate(cellData);
                                    }
                                    if (field === 'password') {
                                        return (
                                            <div>
                                                <button
                                                    onClick={() => togglePasswordVisibility(rowIndex)}
                                                >
                                                    {showPasswords[rowIndex] ? 'Hide' : 'Show'}
                                                </button>
                                                {showPasswords[rowIndex] ? cellData : '******'}
                                            </div>
                                        );
                                    }
                                    return cellData;
                                }}
                            />
                        ))}
                    </Table>
                )}
            </AutoSizer>
        </div>
    );
});

export default Customers;
