import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import dictionary from '../../../Mobx/Dictionary';
import './SearchForm.css';

const SearchForm = observer(() => {
    const search = dictionary.search;

    const [text, setText] = useState(search.text);
    const [field, setField] = useState(search.field);
    const [debouncedText, setDebouncedText] = useState(text); // State for debouncing

    const fields = dictionary.current.fields;

    // ______________________________________________________
    useEffect(() => {
        const handler = setTimeout(() => {
            // Update search after delay (debounce)
            dictionary.setCurrent(null, { text: debouncedText, field }, null, -1);
        }, 500); // Wait 500ms before searching

        return () => {
            clearTimeout(handler); // Cleanup the timeout if text changes before delay ends
        };
    }, [debouncedText, field]); // Run effect when text or field changes

    const handleTextChange = (e) => {
        setText(e.target.value);
        setDebouncedText(e.target.value); // Update debounced text
    };

    const handleFieldChange = (e) => {
        setField(e.target.value);
        dictionary.setCurrent(null, { text: debouncedText, field: e.target.value }, null, -1); // Update immediately when field changes
    };

    const handleReset = () => {
        setText('');
        setField('all');
        setDebouncedText('');
        dictionary.setCurrent(null, { text: '', field: 'all' }, null, -1); // Reset search
    };

    // ______________________________________________________
    const handleKeyDown = (e) => {
        if (e.key === 'Escape') handleReset();
    };

    // ______________________________________________________
    return (
        <div className="search-form" onKeyDown={handleKeyDown}>
            <div className="form-group">
                <input
                    id="searchText"
                    type="text"
                    dir="auto"
                    placeholder="Search Text"
                    value={text}
                    onChange={handleTextChange}
                    autoComplete="off"
                />
            </div>
            <div className="form-group">
                <select id="searchField" value={field} onChange={handleFieldChange}>
                    <option value="all">-- all fields --</option>
                    {fields.map((fieldOption) => (
                        fieldOption === '_id' ? null : (
                            <option key={fieldOption} value={fieldOption}>
                                {fieldOption}
                            </option>
                        )
                    ))}
                </select>
            </div>
            <div className="form-buttons">
                <button className="reset-button" onClick={handleReset}>Reset</button>
            </div>
        </div>
    );
});

export default SearchForm;
