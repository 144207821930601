import React from 'react';
import './DictionaryLogItem.css';
import ReactDOMServer from 'react-dom/server';
import Swal from 'sweetalert2';
import DictionaryLogDetails from './DictionaryLogDetails';

const DictionaryLogItem = ({ logItem }) => {
    const { actionType, audioResult } = logItem.content;

    const formattedDate = new Date(logItem.createdAt).toLocaleString('he-IL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

    // Function to check if the audio result contains an error
    const checkAudioResult = (audioResult) => {
        if (!audioResult) return '---';

        const checkForErrorKey = (obj) => {
            const allKeys = Object.keys(obj);

            // Check if any key is "error"
            if (allKeys.includes('error')) {
                return true;
            }

            // Recursively check nested objects
            for (let key of allKeys) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    if (checkForErrorKey(obj[key])) {
                        return true;
                    }
                }
            }

            return false;
        };

        return checkForErrorKey(audioResult) ? 'error' : 'success';
    };

    const checkValueStatus = (actionType) => {
        return actionType.includes('error') ? 'error' : 'success';
    };

    const valueStatus = checkValueStatus(actionType);
    const audioStatus = checkAudioResult(audioResult);
    const allSuccess = valueStatus === 'success' && audioStatus === 'success';

    const showLogDetails = () => {
        Swal.fire({
            title: allSuccess ? 'Success!' : 'Error!',
            html: ReactDOMServer.renderToString(<DictionaryLogDetails data={logItem} />),
            icon: allSuccess ? 'success' : 'error',
            confirmButtonText: 'OK',
            width: 'auto'
        });
    };

    return (
        <div className='DictionaryLogItem' onClick={showLogDetails}>
            <span>{formattedDate}</span>
            <span className={valueStatus}>Word: {actionType}</span>
            {audioStatus !== '---' &&
                <span className={audioStatus}>Audio: {audioStatus}</span>
            }
        </div>
    );
};

export default DictionaryLogItem;
