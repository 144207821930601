import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import user from '../../../Mobx/User';
import axios from 'axios';
import urls from '../../../Mobx/Urls';
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA
import './Login.css';

const Login = observer(() => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [captchaToken, setCaptchaToken] = useState(''); // store the CAPTCHA token
    const [showCaptcha, setShowCaptcha] = useState(false); // show the CAPTCHA
    
    const navigate = useNavigate(); // Initialize useNavigate

    const onSubmit = async (data) => {
        setLoading(true);
        setError('');

        try {
            const message = await user.login(data?.email, data?.password);
            setLoading(false);
            setError(message);

            if (message) { // Assuming if there's no error, login was successful
                navigate('/dictionary'); // Change URL to /dashboard or any other route
            }
        } catch (err) {
            setLoading(false);
            if (err.response?.status === 429) {
                setShowCaptcha(true); // If the error is 429, show the CAPTCHA
            } else {
                setShowCaptcha(false);
                if (!err.response.data === 'No Token')
                    setError(err.response.data);
            }
        }
    };

    // Login with OAuth 2.0 - using Google as an example
    useEffect(() => {
        onSubmit();
    }, []);

    useEffect(() => {
        if (user.name) { // Assuming if user.name exists, user is logged in
            navigate('/dictionary'); // Change URL to /dashboard or any other route
        }
    }, [user.name, navigate]);

    const onCaptchaChange = async (token) => {
        try {
            const response = await axios.post(urls.user.verifyCaptcha, { token });

            if (response.status === 200) {
                console.log('captcha verified: ' + response.data);
                setCaptchaToken(token);
            } else if (response.status === 403) {
                console.log('captcha not verified: ' + response.data);
                setCaptchaToken('');
            }
        } catch (error) {
            console.error('Error verifying captcha:', error);
            setCaptchaToken('');
        }
    };

    return (
        <div className="Login">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label>Email</label>
                    <input
                        type="email"
                        {...register('email', { required: 'Email is required' })}
                    />
                    {errors.email && <p>{errors.email.message}</p>}
                </div>
                <div>
                    <label>Password</label>
                    <input
                        type="password"
                        {...register('password', { required: 'Password is required' })}
                    />
                    {errors.password && <p>{errors.password.message}</p>}
                </div>

                {/* Show CAPTCHA only if we received a 429 error */}
                {showCaptcha && (
                    <ReCAPTCHA
                        sitekey="6LcjOnAqAAAAADYmBWd90exNIRvSLzUjx7_78Yzi"
                        onChange={onCaptchaChange}
                    />
                )}

                <button type="submit" disabled={loading || (showCaptcha && !captchaToken)}>
                    {loading ? 'Logging in...' : 'Login'}
                </button>
            </form>
            {error && <p>{error}</p>}
        </div>
    );
});

export default Login;
