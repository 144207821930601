import React from 'react';
import { MultiSelect } from 'react-multi-select-component';
import dictionary from '../../../Mobx/Dictionary';
import './ColumnSelector.css';

const ColumnSelector = ({ selectedFields, setSelectedFields }) => {
    const options = dictionary.current.fields.map(field => ({
        label: field.charAt(0).toUpperCase() + field.slice(1),
        value: field
    }));

    const customStrings = {
        "selectSomeItems": "Select columns",
        "allItemsAreSelected": "All columns",
        "clearSearch": "Clear",
        "noOptions": "No columns available",
        "search": "Search columns",
        "selectAll": "Select all"
    };

    return (
        <div className="ColumnSelector">
            <MultiSelect
                options={options}
                value={selectedFields}
                onChange={setSelectedFields}
                labelledBy="Select Columns"
                overrideStrings={customStrings}
                className="multi-select"
            />
        </div>
    );
};

export default ColumnSelector;
