import axios from 'axios';
import urls from '../Mobx/Urls';
import user from '../Mobx/User';

let tokenInterval = null;

// Checks every minute if the token is valid
export function startTokenCheck() {
    console.log('Token check started');
    const tokenCheckInterval = setInterval(async () => {
        try {
            await axios.get(urls.user.verifyToken, { withCredentials: true });
            console.log('Token is valid');
        } catch (error) {
            console.log(error);
            clearInterval(tokenInterval);
            // There is no need to perform an logout here, because the logout is performed by "createInterceptors".
            // user.logout();
        }
    }, 60000); // 1 minute
    tokenInterval = tokenCheckInterval;
    return tokenCheckInterval;
}

// Stops the token validity check
export function stopTokenCheck(tokenCheckInterval) {
    if (tokenCheckInterval) {
        clearInterval(tokenCheckInterval);
    }
}
