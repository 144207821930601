import React from 'react';
import { observer } from 'mobx-react';
import dictionary from '../../../Mobx/Dictionary';
import './DictionaryTypeSelector.css';

const DictionaryTypeSelector = observer(() => {

    const handleToggle = () => {
        const newDictionaryType = dictionary.current.dictionaryType === 'dictionary_eng_to_heb'
            ? 'dictionary_heb_to_eng'
            : 'dictionary_eng_to_heb';
        dictionary.setCurrent(newDictionaryType, null, null, -1); // -1 To prevent slow loading of the UI.
    };

    return (
        <div className="dictionary-type-toggle">
            <button
                className="toggle-button"
                onClick={handleToggle}
            >
                {dictionary.current.dictionaryType === 'dictionary_eng_to_heb' ? 'Eng > Heb' : 'Heb > Eng'}
            </button>
        </div>
    );
});

export default DictionaryTypeSelector;
