import axios from 'axios';
import urls from './Urls';
import { action, get, makeObservable, observable, runInAction } from "mobx";
import dictionary from './Dictionary';

class Groups {
    categories = { dictionary_eng_to_heb: [], dictionary_heb_to_eng: [] }; // array of objects: _id, name, sum

    pos = { dictionary_eng_to_heb: [], dictionary_heb_to_eng: [] }; // array of strings
    latin = { dictionary_eng_to_heb: [], dictionary_heb_to_eng: [] }; // array of strings
    olami = { dictionary_eng_to_heb: [], dictionary_heb_to_eng: [] }; // array of strings

    band = { dictionary_eng_to_heb: [], dictionary_heb_to_eng: [] }; // array of numbers
    priority = { dictionary_eng_to_heb: [], dictionary_heb_to_eng: [] }; // array of numbers

    isLoading = { loaded: 0, total: 0 };

    constructor() {
        makeObservable(this, {
            categories: observable,
            pos: observable,
            latin: observable,
            olami: observable,
            band: observable,
            priority: observable,

            isLoading: observable,

            getCategories: action,
            createCategory: action,
            updateCategory: action,
            deleteCategory: action,

            getGroups: action,
            countValuesPerCategory: action,

            clear: action
        });
    }

    // ______________________________________________________________________________________
    // categories: CRUD - Create, Read, Update, Delete
    async getCategories() {
        if (this.categories.dictionary_eng_to_heb.length === 0) {

            let loadedMB, totalMB;

            const response = await axios.get(urls.categories.category, {
                withCredentials: true,
                onDownloadProgress: progressEvent => {
                    loadedMB = (progressEvent.loaded / 1024 / 1024).toFixed(2);
                    totalMB = (progressEvent.total / 1024 / 1024).toFixed(2);
                    this.isLoading.loaded = loadedMB;
                    this.isLoading.total = totalMB;
                }
            });

            const categories = response.data;
            setTimeout(() => { this.isLoading.loaded = totalMB; }, 200);

            runInAction(() => {
                this.categories = categories;
            });
        }
    }

    async createCategory(category) {
        const response = await axios.post(urls.categories.category, category, {
            withCredentials: true
        });
        const newCategory = response.data;

        runInAction(() => {
            newCategory.sum = 0;
            this.categories[category.dictionaryType].unshift(newCategory);
        });
        return `"${newCategory.name}" created.`;
    }

    async updateCategory(category) {
        const response = await axios.patch(urls.categories.category + category._id, category, {
            withCredentials: true
        });
        const updateCategory = response.data;

        runInAction(() => {
            updateCategory.sum = get(this.categories[category.dictionaryType].find(c => c._id === category._id), 'sum');
            const index = this.categories[category.dictionaryType].findIndex(c => c._id === category._id);
            this.categories[category.dictionaryType][index] = updateCategory;
        });
        return updateCategory;
    }

    async deleteCategory(category) {
        const response = await axios.delete(urls.categories.category + category._id, {
            withCredentials: true
        });
        const status = response.status;
        if (status === 204) {
            runInAction(() => {
                let index = this.categories[category.dictionaryType].findIndex(c => c._id === category._id);
                this.categories[category.dictionaryType].splice(index, 1);
            });
            return `"${category.name}" deleted.`;
        }
    }

    // ______________________________________________________________________________________
    // pos, latin, olami, band, priority: get the groups from the dictionary and sort them
    getGroups(dictionaryType) {
        if (this.band[dictionaryType].length > 0) return;

        let array = dictionary[dictionaryType];

        const result = {};

        array.forEach(obj => {
            for (const key in obj) {
                if (!['pos', 'latin', 'olami', 'band', 'priority'].includes(key)) {
                    continue; // only these keys are allowed
                }

                if (!result[key]) {
                    result[key] = new Set(); // create a new Set for each key
                }

                if (Array.isArray(obj[key])) { // if the value is an array
                    obj[key].forEach(item => result[key].add(item));
                } else { // if the value is a string
                    result[key].add(obj[key]);
                }
            }
        });

        // convert Set to Array
        for (const key in result) {
            result[key] = Array.from(result[key]);
        }

        // sort the arrays
        const resultSort = this.sortObjectArrays(result);

        // assign the sorted arrays to the MobX observable
        this.pos[dictionaryType] = resultSort.pos;
        this.latin[dictionaryType] = resultSort.latin;
        this.olami[dictionaryType] = resultSort.olami;
        this.band[dictionaryType] = resultSort.band;
        this.priority[dictionaryType] = resultSort.priority
    }
    // ______________________________________________________________________________________
    sortObjectArrays(obj) {
        const sortedObj = {};

        for (const key in obj) {
            if (Array.isArray(obj[key])) {
                sortedObj[key] = [...obj[key]].sort((a, b) => {
                    if (typeof a === 'string' && typeof b === 'string') {
                        return a.localeCompare(b, undefined, { sensitivity: 'base' });
                    } else {
                        return a > b ? 1 : -1;
                    }
                });
            } else {
                sortedObj[key] = obj[key];
            }
        }

        return sortedObj;
    }
    // ______________________________________________________________________________________
    countValuesPerCategory(dictionaryType) {
        // return an object where the keys are the category names and the values ​​are the count
        const categoryCounts = {};

        const dictionaryArray = dictionary[dictionaryType];

        dictionaryArray.forEach(entry => {
            if (Array.isArray(entry.categories)) {
                entry.categories.forEach(category => {
                    if (!categoryCounts[category._id]) {
                        // If the category does not exist in the object, we will add it and initialize the count to one
                        categoryCounts[category._id] = 1;
                    } else {
                        // If the category already exists, we'll increment its count
                        categoryCounts[category._id]++;
                    }
                });
            }
        });

        // Update the sum field for each category in the categories data structure
        this.categories[dictionaryType].forEach(category => {
            if (categoryCounts[category._id]) {
                category.sum = categoryCounts[category._id]; // עדכן את שדה הספירה
            } else {
                category.sum = 0; // If there are no entries for the category, set the sum to zero
            }
        });
    }
    // ______________________________________________________________________________________
    clear() {
        this.categories = { dictionary_eng_to_heb: [], dictionary_heb_to_eng: [] };
        this.pos = { dictionary_eng_to_heb: [], dictionary_heb_to_eng: [] };
        this.latin = { dictionary_eng_to_heb: [], dictionary_heb_to_eng: [] };
        this.olami = { dictionary_eng_to_heb: [], dictionary_heb_to_eng: [] };
        this.band = { dictionary_eng_to_heb: [], dictionary_heb_to_eng: [] };
        this.priority = { dictionary_eng_to_heb: [], dictionary_heb_to_eng: [] };
        this.isLoading = { loaded: 0, total: 0 };
    }
}

const groups = new Groups();
export default groups;