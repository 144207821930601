import "./CreateSubscription.css";
import { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"; // npm install @paypal/react-paypal-js
import Swal from 'sweetalert2'; // npm install sweetalert2
import urls from "../../../Mobx/Urls"; // Import the URLs
import customers from "../../../Mobx/Customers";

function CreateSubscription() {
    // State for the message
    const [message, setMessage] = useState("");
    // State for the selected plan
    const [selectedPlan, setSelectedPlan] = useState("monthly"); // monthly, yearly
    const [selectedCurrency, setSelectedCurrency] = useState("ILS"); // USD, ILS

    // PayPal Client ID - Sandbox OR Production
    // const clientId = process.env.NODE_ENV === "development" ?
    //     'Adn655QD0WgMx8QVKZPHWPxrirVdkaJUlKrlkzxN6zkggPJ7gPTVZh6yXDkFjI9ss2c2MrkxVEZblk8o' : // Sandbox
    //     'AT4sMJRqLH8jJuNsung_YEf0efD4WqupoVuI4kcfavbMwlbOTF9Fz4gibbO8-ZcGZluhAQQ7b3WsB7hK' // Production

    const clientId = 'Adn655QD0WgMx8QVKZPHWPxrirVdkaJUlKrlkzxN6zkggPJ7gPTVZh6yXDkFjI9ss2c2MrkxVEZblk8o' // Sandbox

    // PayPal Button Options
    const initialOptions = {
        "clientId": clientId,
        "enable-funding": "card",
        "disable-funding": "paylater",
        "data-sdk-integration-source": "integrationbuilder_sc",
        vault: "true",
        intent: "subscription",
    };

    // Step 1 - Creating a subscription
    async function createSubscription_step1(plan, currency) {
        const response = await fetch(urls.payment.createSubscription_step1, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                plan,
                currency,
                userAction: "SUBSCRIBE_NOW"
            }),
        });
        const data = await response.json();
        return data;
    }

    // Step 2 - Opening the site to the user
    async function createSubscription_step2(subscriptionID) {
        const response = await fetch(urls.payment.createSubscription_step2, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                subscriptionId: subscriptionID
            }),
        });
        return response.status;
    }

    // The buttons must be reloaded every time "selectedPlan" changes, so that the new value is updated in the buttons.
    const [loadingPayPalButtons, setLoadingPayPalButtons] = useState(true);
    useEffect(() => {
        setMessage("");
        setLoadingPayPalButtons(false);
        setTimeout(() => { setLoadingPayPalButtons(true); }, 1);
    }, [selectedPlan, selectedCurrency]);

    // Render the component
    return (
        <div className="CreateSubscription">
            {/* Choosing a plan */}
            <label htmlFor="plan-select">Choose a plan:</label>
            <select
                id="plan-select"
                value={selectedPlan}
                onChange={(e) => setSelectedPlan(e.target.value)}
            >
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly </option>
            </select>
            <label htmlFor="plan-select">Choose a Currency:</label>
            <select
                id="currency-select"
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
            >
                <option value="ILS">ILS</option>
                <option value="USD">USD</option>
            </select>
            <br />
            {/* Start of PayPal buttons */}
            {loadingPayPalButtons &&
                <div>
                    <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons

                            // ================= PayPal Button Options =================
                            style={{
                                shape: 'rect',
                                color: 'gold',
                                layout: 'vertical',
                                // label: 'subscribe'
                                label: 'paypal'
                            }}

                            // ================= Step 1 - Creating a subscription - when the user clicks the first button. =================
                            createSubscription={async () => {
                                try {
                                    // action
                                    // Choosing the plan - Monthly or Yearly
                                    const data_step1 = await createSubscription_step1(selectedPlan, selectedCurrency);

                                    // messages
                                    if (data_step1?.id) {
                                        setMessage(`Successful subscription...`);
                                        return data_step1.id;
                                    }
                                    else {
                                        console.error(
                                            { callback: "createSubscription", serverResponse: data_step1 },
                                            JSON.stringify(data_step1, null, 2),
                                        );

                                        const errorDetail = data_step1?.details?.[0];
                                        setMessage(
                                            `Could not initiate PayPal Subscription... ${errorDetail?.issue || ""
                                            } ${errorDetail?.description || data_step1?.message || ""} ` +
                                            (data_step1?.debug_id ? `(${data_step1.debug_id})` : "")
                                        );
                                    }

                                } catch (error) {
                                    console.error(error);
                                    setMessage(`Could not initiate PayPal Subscription...${error}`);
                                }
                            }}

                            // ================= Step 2 - Opening the site to the user - when the user clicks the second button. =================
                            onApprove={async (data, actions) => {
                                /*
                                  No need to activate manually since SUBSCRIBE_NOW is being used.
                                  Learn how to handle other user actions from our docs:
                                  https://developer.paypal.com/docs/api/subscriptions/v1/#subscriptions_create
                                */
                                // action
                                const data_step2 = await createSubscription_step2(data.subscriptionID);

                                // messages
                                if (data.orderID && data_step2 === 201) {

                                    setMessage(`Subscription Successfully Activated! ${data.orderID, data_step2, data.subscriptionID}`);
                                    Swal.fire({
                                        title: 'Subscription Successfully Activated!',
                                        text: `Selected plan: ${selectedPlan}. Subscription ID: ${data.subscriptionID}.`,
                                        icon: 'success',
                                        confirmButtonText: 'OK',
                                        width: 'auto'
                                    });

                                } else {
                                    setMessage(`Failed to activate the subscription: ${data.subscriptionID}.`);
                                    Swal.fire({
                                        title: 'Error!',
                                        text: `Failed to activate the subscription: ${data.subscriptionID}.`,
                                        icon: 'error',
                                        confirmButtonText: 'OK',
                                        width: 'auto'
                                    });
                                }
                                customers.getCustomers();
                            }}

                        />
                    </PayPalScriptProvider>
                </div>
            }
            {/* End of PayPal buttons */}
            <p>{message}</p>
        </div>
    );
}

export default CreateSubscription;
