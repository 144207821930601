import React from 'react';
import './UserNameDisplay.css';
import { observer } from 'mobx-react';
import user from '../../../Mobx/User';

const UserNameDisplay = observer(() => {
    return (
        <div className="UserNameDisplay">
            {user.name ? (
                <span>{user.name}</span>
            ) : (
                <span>Please log in.</span>
            )}
        </div>
    );
});

export default UserNameDisplay;
