import React from 'react';
import './DictionaryLogDetails.css';

// Recursive function that formats the value of each field
function formatValue(value) {
    if (typeof value === 'boolean') {
        return value ? 'True' : 'False';
    }
    if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
            return (
                <ul>
                    {value.map((item, index) => (
                        <li key={index}>{formatValue(item)}</li>
                    ))}
                </ul>
            );
        } else {
            return (
                <table>
                    <tbody>
                        {Object.entries(value).map(([key, nestedValue], index) => (
                            <tr key={index}>
                                <td>{key}</td>
                                <td>{formatValue(nestedValue)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }
    }
    return value; // For simple values like numbers and strings
}

const DictionaryLogDetails = ({ data }) => {
    return (
        <table className="DictionaryLogDetails">
            <thead>
                <tr>
                    <th>Field</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(data).map(([key, value], index) => (
                    <tr key={index}>
                        <td>{key}</td>
                        <td>{formatValue(value)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DictionaryLogDetails;
