// Inject CSS styles directly into the document head
function injectStyles() {
    const style = document.createElement('style');
    style.innerHTML = `
        .convertMessageToHTML {
            width: auto;
            border-collapse: collapse;
            margin: 10px 0;
            font-size: 12px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            background-color: #fafafa;
            border-radius: 4px;
            overflow: hidden;
            border: 1px solid #e0e0e0; /* גבול עדין */
        }

        .convertMessageToHTML th {
            background-color: #3f51b5;
            color: white;
            text-align: left;
            padding: 6px 10px; /* הקטנת השוליים הפנימיים */
            font-weight: normal;
            border-bottom: 1px solid #e0e0e0; /* גבול עדין */
            white-space: nowrap;
        }

        .convertMessageToHTML td {
            padding: 6px 10px; /* הקטנת השוליים הפנימיים */
            color: #333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-bottom: 1px solid #e0e0e0; /* גבול עדין */
        }

        .convertMessageToHTML tr:nth-child(even) {
            background-color: #f5f5f5; /* צבע רקע עדין לשורות זוגיות */
        }

        .convertMessageToHTML ul {
            padding-left: 15px;
            margin: 3px 0;
            list-style-type: disc;
        }

        .convertMessageToHTML li {
            margin: 2px 0;
        }

        .convertMessageToHTML th, 
        .convertMessageToHTML td {
            border: none; /* הסרת גבולות מיותרים */
        }

        .convertMessageToHTML tr:last-child td {
            border-bottom: none;
        }
    `;
    document.head.appendChild(style);
}

injectStyles();

// ____________________________________________________________
// Recursive function that formats the value of each field
function formatValue(value) {
    if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
            return `<ul>${value.map(item => `<li>${formatValue(item)}</li>`).join('')}</ul>`;
        } else {
            return `
              <table>
                ${Object.entries(value).map(([key, nestedValue]) => `
                  <tr>
                    <td>${key}</td>
                    <td>${formatValue(nestedValue)}</td>
                  </tr>
                `).join('')}
              </table>
            `;
        }
    }
    return value; // For simple values ​​like numbers and strings
}

export function convertMessageToHTML(data) {
    return `
      <table class="convertMessageToHTML">
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          ${Object.entries(data).map(([key, value]) => `
            <tr>
              <td>${key}</td>
              <td>${formatValue(value)}</td>
            </tr>
          `).join('')}
        </tbody>
      </table>
    `;
};
