import React from 'react';
import './DictionaryStatus.css';
import { observer } from 'mobx-react';
import dictionary from '../../../Mobx/Dictionary';

const DictionaryStatus = observer(() => {
    const getStatusIcon = () => {
        switch (dictionary.isLoading.updateDictionary) {
            case 'success':
                return <i className="fa-solid fa-check" />;
            case 'loading':
                return <i className="fa-solid fa-spinner fa-spin" />;
            case 'error':
                return <i className="fa-solid fa-xmark" />;
            default:
                return <i className="fa-solid fa-check" />;
        }
    };

    return (
        <div className='DictionaryStatus'>
            {getStatusIcon()}
        </div>
    );
});

export default DictionaryStatus;
