import React, { useState } from "react";
import ColumnSelector from "../../Dictionary/ColumnSelector/ColumnSelector";
import ExportToExcel from "../../Dictionary/ExportToExcel/ExportToExcel";
// import TextSizeSelector from "../../Dictionary/TextSizeSelector/TextSizeSelector";
import SortFieldSelector from "../../Dictionary/SortFieldSelector/SortFieldSelector"; // ייבוא של רכיב המיון
import "./SettingsPanel.css";

function SettingsPanel({
    selectedFields, setSelectedFields,
    textSize, setTextSize,
}) {
    const [isOpen, setIsOpen] = useState(false);

    const togglePanel = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="SettingsPanel">
            <button onClick={togglePanel} className="settings-btn">
                <i className="fa-solid fa-gear"></i>
            </button>
            <div className={`settings-panel ${isOpen ? "open" : ""}`}>

                <div className="header-container">
                    <h2>Settings</h2>
                    <button className="close-btn" onClick={togglePanel}>
                        {/* &times; */}
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                {/* <div className="setting text-size">
                    <label>Text Size:</label>
                    <TextSizeSelector textSize={textSize} setTextSize={setTextSize} />
                </div> */}

                <div className="setting">
                    <ExportToExcel />
                </div>

                <div className="setting">
                    <label>Select And Sort Columns</label>
                    <ColumnSelector
                        selectedFields={selectedFields}
                        setSelectedFields={setSelectedFields}
                    />
                </div>

                <div className="setting">
                    <label>Sort By Field</label>
                    <SortFieldSelector />
                </div>

            </div>

            <div className={`overlay ${isOpen ? "show" : ""}`} onClick={togglePanel}></div>
        </div>
    );
}

export default SettingsPanel;
