import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import user from '../../../Mobx/User';
import './Logout.css';

const Logout = observer(() => {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleLogout = () => {
        const message = user.logout();
        navigate('/login'); // Redirect to login after logout
    };

    return (
        <div className="Logout">
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
});

export default Logout;
