import React, { useState, useEffect } from 'react';
import dictionary from '../../../Mobx/Dictionary';
import './SortFieldSelector.css';

const SortFieldSelector = () => {
    const [sortField, setSortField] = useState(dictionary.current.sort?.field);
    const [sortOrder, setSortOrder] = useState(dictionary.current.sort?.order);
    const [options, setOptions] = useState([]);

    // Update options based on dictionary type
    useEffect(() => {
        const dictionaryType = dictionary.current.dictionaryType;
        if (dictionaryType === 'dictionary_eng_to_heb') {
            setOptions(dictionary.dictionary_eng_to_heb_fields);
        } else if (dictionaryType === 'dictionary_heb_to_eng') {
            setOptions(dictionary.dictionary_heb_to_eng_fields);
        }
    }, [dictionary.current.dictionaryType]);

    const handleSortChange = (event) => {
        const selectedSortField = event.target.value;
        setSortField(selectedSortField);
        dictionary.setCurrent(null, null, null, -1, selectedSortField, sortOrder); // מעביר את sortOrder
    };

    const handleSortOrderChange = (event) => {
        const selectedSortOrder = event.target.value;
        setSortOrder(selectedSortOrder);
        dictionary.setCurrent(null, null, null, -1, sortField, selectedSortOrder); // מעביר את sortField
    };

    return (
        <div className='SortFieldSelector'>
            <label htmlFor="sortField">Sort by:</label>
            <select id="sortField" value={sortField === 'originalWithoutPunctuation' ? 'original' : sortField} onChange={handleSortChange}>
                {options.map((field) => (
                    <option key={field} value={field}>
                        {field}
                    </option>
                ))}
            </select>
            <br />
            <label htmlFor="sortOrder">Order:</label>
            <select id="sortOrder" value={sortOrder} onChange={handleSortOrderChange}>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
            </select>
        </div>
    );
};

export default SortFieldSelector;
