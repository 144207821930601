import React from 'react';
import './DictionaryLogList.css';
import { observer } from 'mobx-react-lite';
import { List } from 'react-virtualized';
import DictionaryLogItem from './DictionaryLogItem';
import dictionaryLog from '../../../Mobx/DictionaryLog';

const DictionaryLogList = observer(() => {
    const logs = dictionaryLog.logs;

    // Function to render each row in the log list
    const rowRenderer = ({ index, key, style }) => {
        if (!logs || logs.length === 0) {
            return null; // If logs are not loaded yet or empty, return null
        }

        const logItem = logs[index];
        return (
            <div key={key} style={style} >
                <DictionaryLogItem logItem={logItem} />
            </div>
        );
    };

    return (
        <div className='DictionaryLogList'>
            <List
                width={500}
                height={500}
                rowHeight={35}
                rowCount={logs.length}
                rowRenderer={rowRenderer}
                overscanRowCount={3}
            />
        </div>
    );
});

export default DictionaryLogList;
