import axios from "axios";
import urls from './Urls';
import { action, get, makeObservable, observable, runInAction } from "mobx";

class DictionaryLog {
    logs = [];
    isLoading = { loaded: 0, total: 0 };

    constructor() {
        makeObservable(this, {
            logs: observable,
            isLoading: observable,
            getLogs: action,
            addLog: action,
            clear: action
        });
    }

    async getLogs() {
        if (this.logs.length === 0) {

            let loadedMB, totalMB;

            const response = await axios.get(urls.dictionaryLog.getAll, {
                withCredentials: true,
                onDownloadProgress: progressEvent => {
                    loadedMB = (progressEvent.loaded / 1024 / 1024).toFixed(2);
                    totalMB = (progressEvent.total / 1024 / 1024).toFixed(2);
                    this.isLoading.loaded = loadedMB;
                    this.isLoading.total = totalMB;
                }
            });

            const logs = response.data;
            setTimeout(() => { this.isLoading.loaded = totalMB; }, 200);

            runInAction(() => {
                this.logs = logs;
            });
        }
    }

    addLog(log) {
        this.logs.unshift(log);
    }

    clear() {
        this.logs = [];
        this.isLoading = { loaded: 0, total: 0 };
    }
}

const dictionaryLog = new DictionaryLog();
export default dictionaryLog;
