import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Customers from '../../Customers/Customers';
import DictionaryPage from '../../Dictionary/DictionaryPage/DictionaryPage';
import Login from '../../User/Login/Login';
import Categories from '../../Categories/Categories';
import Pictures from '../../Pictures/Pictures';

function Routing() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dictionary" replace />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/dictionary" element={<DictionaryPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/pictures" element={<Pictures />} />
        </Routes>
    );
}

export default Routing;
