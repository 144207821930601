import React from 'react';
import "./Navbar.css";
import { NavLink } from 'react-router-dom';

function Navbar() {
    return (
        <nav className="Navbar">
            <ul>
                <li>
                    <NavLink exact to="/dictionary" activeClassName="active">Dictionary</NavLink>
                </li>
                <li>
                    <NavLink exact to="/categories" activeClassName="active">Categories</NavLink>
                </li>
                <li>
                    <NavLink exact to="/pictures" activeClassName="active">Pictures</NavLink>
                </li>
                <span>|</span>
                <li>
                    <NavLink exact to="/customers" activeClassName="active">Customers</NavLink>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;
