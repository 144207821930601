import React, { useState, useEffect, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import SearchForm from '../SearchForm/SearchForm';
import CreateDictionaryEntry from '../DictionaryList/CreateDictionaryEntry';
import DictionaryList from '../DictionaryList/DictionaryList';
import DictionaryTypeSelector from '../DictionaryTypeSelector/DictionaryTypeSelector';
import DictionaryStatus from '../DictionaryLog/DictionaryStatus';
import NumberOfResults from '../NumberOfResults/NumberOfResults';
import Popup from '../../SharedArea/Popup/Popup';
import dictionary from '../../../Mobx/Dictionary';
import SettingsPanel from '../../SharedArea/SettingsPanel/SettingsPanel';
import './DictionaryPage.css';
import DictionaryLogList from '../DictionaryLog/DictionaryLogList';

const DictionaryPage = observer(() => {
    // _____________________________________________________________________
    useLayoutEffect(() => {
        dictionary.setCurrent(null, null, null, -1); // -1 To prevent slow loading of the UI.
    }, []);

    // _____________________________________________________________________
    const [isPopupOpen_logs, setIsPopupOpen_logs] = useState(false);
    const openPopup_logs = () => setIsPopupOpen_logs(true);
    const closePopup_logs = () => setIsPopupOpen_logs(false);

    const [isPopupOpen_create, setIsPopupOpen_create] = useState(false);
    const openPopup_create = () => setIsPopupOpen_create(true);
    const closePopup_create = () => setIsPopupOpen_create(false);

    // _____________________________________________________________________
    let savedFields = localStorage.getItem('selectedFields');
    if (savedFields) { savedFields = JSON.parse(savedFields); }

    let defaultFields = savedFields || ['original', 'trns', 'createdAt', 'updatedAt'];

    const [selectedFields, setSelectedFields] = useState(
        dictionary.current.fields
            .filter(field => defaultFields.includes(field))
            .map(field => ({
                label: field.charAt(0).toUpperCase() + field.slice(1),
                value: field
            }))
    );

    useEffect(() => {
        localStorage.setItem('selectedFields', JSON.stringify(selectedFields.map(f => f.value)));
    }, [selectedFields]);

    // _____________________________________________________________________
    const savedTextSize = localStorage.getItem('textSize') || '20px';
    const [textSize, setTextSize] = useState(savedTextSize);

    useEffect(() => {
        localStorage.setItem('textSize', textSize);
    }, [textSize]);

    // _____________________________________________________________________
    return (
        <div className="DictionaryPage">
            <header>
                {/* -------------------------------------------------------------- */}
                <DictionaryTypeSelector />
                <NumberOfResults />
                <SearchForm />
                {/* -------------------------------------------------------------- */}
                <div>
                    <span className="dictionary-status-span" onClick={openPopup_logs}>
                        <DictionaryStatus />
                    </span>
                    <Popup
                        isOpen={isPopupOpen_logs}
                        onClose={closePopup_logs}
                        title={'Dictionary Logs'}
                    >
                        <DictionaryLogList />
                    </Popup>
                </div>
                {/* -------------------------------------------------------------- */}
                <div>
                    <button className="create-entry-button" onClick={openPopup_create}>Create</button>
                    <Popup
                        isOpen={isPopupOpen_create}
                        onClose={closePopup_create}
                        title={`Create ${dictionary.current.dictionaryType === 'dictionary_eng_to_heb' ? 'English' : 'Hebrew'} Entry`}
                    >
                        <CreateDictionaryEntry />
                    </Popup>
                </div>
                {/* -------------------------------------------------------------- */}
                <SettingsPanel
                    selectedFields={selectedFields}
                    setSelectedFields={setSelectedFields}

                    textSize={textSize}
                    setTextSize={setTextSize}
                />
                {/* -------------------------------------------------------------- */}
            </header>

            <main>
                <DictionaryList
                    selectedFields={selectedFields.map(f => f.value)}
                    textSize={textSize}
                />
            </main>
        </div>
    );
});

export default DictionaryPage;
