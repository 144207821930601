import React, { useEffect } from 'react';

const Pictures = () => {
    useEffect(() => {
        // Prevent scrolling
        document.body.style.overflow = 'hidden';

        // Clean up function to restore the default behavior
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h2 style={styles.message}>🚧 The area is under construction 🚧</h2>
                <p style={styles.subMessage}>We're working hard to bring this section to life. Stay tuned!</p>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#e0eafc',
        backgroundImage: 'linear-gradient(315deg, #e0eafc 0%, #cfdef3 74%)',
        fontFamily: "'Roboto', sans-serif",
    },
    card: {
        textAlign: 'center',
        padding: '20px 40px',
        borderRadius: '12px',
        backgroundColor: '#ffffff',
        boxShadow: '0 8px 15px rgba(0, 0, 0, 0.1)',
        maxWidth: '550px',
        width: '100%',
    },
    message: {
        color: '#2c3e50',
        fontSize: '28px',
        fontWeight: 'bold',
        marginBottom: '15px',
    },
    subMessage: {
        color: '#7f8c8d',
        fontSize: '18px',
    },
};

export default Pictures;
